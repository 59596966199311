<header>
  <!-- Header Start -->
  <div class="header-area">
    <div class="main-header">

      <div class="header-mid d-none d-md-block">
        <div class="container">
          <div class="row d-flex align-items-center">
            <!-- Logo -->
            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="logo">
                <a href="index.html"
                  ><img src="assets/img/logo/logo-enstfr.png" alt=""
                /></a>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9">
              <div class="header-banner f-right">
                <img src="assets/img/logo/logo2.PNG" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bottom header-sticky">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-10 col-lg-10 col-md-12 header-flex">
              <!-- sticky -->

              <!-- Main-menu -->

                <div class="main-menu d-none d-md-block">
                  <nav>
                      <ul id="navigation">
                          <li><a href="index.html">Accueil</a></li>
                          <li><a href="categori.html">Organigramme de L'E.N.S</a></li>
                          <li><a href="about.html">Direction des études et des stages</a></li>
                          <li><a href="#">Conseil Scientique</a>
                              <ul class="submenu">
                                  <li><a href="elements.html">Composition et CV des membres</a></li>

                              </ul>
                          </li>
                          <li><a href="#">Conseil de Descipline</a>
                            <ul class="submenu">
                                <li><a href="elements.html">Composition et CV des membres</a></li>

                            </ul>
                        </li>
                      </ul>
                  </nav>
              </div>
              </div>

            <div class="col-xl-2 col-lg-2 col-md-4">
              <div class="header-right-btn f-right d-none d-lg-block">
                <i class="fas fa-search special-tag"></i>
                <div class="search-box">
                  <form action="#">
                    <input type="text" placeholder="Search" />
                  </form>
                </div>
              </div>
            </div>
            <!-- Mobile Menu -->
            <div class="col-12">
              <div class="mobile_menu d-block d-md-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End -->
</header>
<nav>
  <ul id="navigation">
    <li><a href="index.html"></a></li>


  </ul>
  <ul id="navigation">
    <li><a href="index.html"></a></li>


  </ul>
  <ul id="navigation">
    <li><a href="index.html"></a></li>


  </ul>
  <ul id="navigation">
    <li><a href="index.html"></a></li>


  </ul>
  <ul id="navigation">
    <li><a href="index.html"></a></li>


  </ul>
</nav>
