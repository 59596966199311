<app-navbar></app-navbar>

<!--================Blog Area =================-->
<section class="blog_area single-post-area section-padding">
  <div class="container">
     <div class="row">
        <div class="col-lg-8 posts-list">
           <div class="single-post">
              <div class="feature-img">
                 <img class="img-fluid" src="assets/img/blog/juridique.jpg" alt="">
              </div>
              <div class="blog_details">
                 <h2>Textes Juridiques
                 </h2>


                 <p>
                  L’École Normale Supérieure, l’institution universitaire la plus ancienne et le premier noyau de l’enseignement supérieur moderne de la Tunisie indépendante, a ouvert ses portes dès octobre 1958. Elle a pour mission d’assurer la formation d’enseignants, de cadres et de chercheurs de haut niveau dont le pays a un si grand besoin.
                 </p>



              </div>
           </div>




        </div>
        <div class="col-lg-4">
           <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget search_widget">

              </aside>
              <aside class="single_sidebar_widget post_category_widget">

                 <ul class="list cat-list">
                    <li>
                       <a [routerLink]="['/cadrejuridique/textesgeneraux']" class="d-flex">
                          <p>Textes  généraux</p>

                       </a>
                    </li>
                    <li>
                       <a [routerLink]="['/cadrejuridique/les-regimes']" class="d-flex">
                          <p>Régimes des études et examens  et des stages</p>

                       </a>
                    </li>
                    <li>
                       <a [routerLink]="['/cadrejuridique/admission']" class="d-flex">
                          <p>Admission à l’ENS ( concours d’accès )</p>

                       </a>
                    </li>
                    <li>
                       <a [routerLink]="['/cadrejuridique/concours-dagregation']" class="d-flex">
                          <p>Concours National d’agrégation</p>

                       </a>
                    </li>
                    <li>
                       <a [routerLink]="['/cadrejuridique/bourse-et-vie-universitaire']" class="d-flex">
                          <p>Bourse et vie universitaire</p>

                       </a>
                    </li>
                    <li>
                       <a [routerLink]="['/cadrejuridique/statuts-particuliers']" class="d-flex">
                          <p>Statuts particuliers</p>

                       </a>
                    </li>
                 </ul>
              </aside>




           </div>
        </div>
     </div>
  </div>
</section>
