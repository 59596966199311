<app-navbar></app-navbar>
<h2>Concours d’agrégation
</h2>
<div class="blog_details" *ngFor="let text of texts">
  <a class="d-inline-block" >
      <h2>{{text.title}}</h2>
  </a>
  <p> {{text.description}}</p>
  <ul class="blog-info-link">

      <li><a ><i class="fa fa-calendar"></i> {{text.date}}</a></li>
  </ul>
</div>
<app-footer></app-footer>
