<app-navbar></app-navbar>

<main>
  <!-- Trending Area Start -->
  <div class="trending-area fix">
    <div class="container">
      <div class="trending-main">
        <!-- Trending Tittle -->
        <div class="row">
          <div class="col-lg-12">
            <div class="trending-tittle">
              <strong>Actualité</strong>
              <!-- <p>Rem ipsum dolor sit amet, consectetur adipisicing elit.</p> -->
              <div class="trending-animated">
                <ul id="js-news" class="js-hidden">
                  <li class="news-item">
                    Concours d’accès à l’E.N.S Juillet 2021 <br />
                    2021
                  </li>
                  <li class="news-item">
                    Le département des sciences fondamentales assure une
                    formation
                  </li>
                  <li class="news-item">
                    Le département des sciences Humaines assure une
                    formation
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <!-- Trending Top -->
            <div class="trending-top mb-30">
              <div class="trend-top-img">
                <img src="assets/img/trending/concours.png" alt="" />
                <div class="trend-top-cap">
                  <span>Concours</span>
                  <h2>
                    <a href="details.html"
                      >Concours d’accès à l’E.N.S Juillet 2021 <br />
                      2021</a
                    >
                  </h2>
                </div>
              </div>
            </div>
            <!-- Trending Bottom -->
          </div>
          <!-- Riht content -->
          <div class="col-lg-4">
            <div class="trand-right-single d-flex">
              <div class="trand-right-img">
                <img src="assets/img/trending/sciences-fond.jpg" alt="" />
              </div>
              <div class="trand-right-cap">
                <span class="color1">Sciences Fondamentales</span>
                <h4>
                  <a href="details.html"
                    >Le département des sciences fondamentales assure une
                    formation .....</a
                  >
                </h4>
              </div>
            </div>
            <div class="trand-right-single d-flex">
              <div class="trand-right-img">
                <img src="assets/img/trending/sciences-hum.jpg" alt="" />
              </div>
              <div class="trand-right-cap">
                <span class="color3">Sciences Humaines & Sociales</span>
                <h4><a href="details.html">Sciences Humaines & Sociales</a></h4>
              </div>
            </div>
            <div class="trand-right-single d-flex">
              <div class="trand-right-img">
                <img src="assets/img/trending/langues.jpg" alt="" />
              </div>
              <div class="trand-right-cap">
                <span class="color2">Département des Langues</span>
                <h4>
                  <a href="details.html"
                    >Le département des Langues offre une formation en :</a
                  >
                </h4>
              </div>
            </div>

            <div class="trand-right-single d-flex">
              <div class="trand-right-img">
                <img src="assets/img/trending/agregation.jpg" alt="" />
              </div>
              <div class="trand-right-cap">
                <span class="color1">Concours d’Agrégation</span>
                <h4>
                  <a href="details.html"
                    >Le concours d’agrégation a pour but de recruter des
                    enseignants.....</a
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Trending Area End -->
  <!--   Weekly-News start -->
  <div class="weekly-news-area pt-50">
    <div class="container">
      <div class="weekly-wrapper">
        <!-- section Tittle -->
        <div class="row">
          <div class="col-lg-12">
            <div class="section-tittle mb-30">
              <h3>Albums photo</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="weekly-news-active dot-style d-flex dot-style">
              <div class="weekly-single">
                <div class="weekly-img">
                  <img src="assets/img/news/1.jpg" alt="" />
                </div>
                <div class="weekly-caption">
                  <h4><a href="#">Prise de vues E.N.S</a></h4>
                </div>
              </div>

              <div class="weekly-single">
                <div class="weekly-img">
                  <img src="assets/img/news/1.jpg" alt="" />
                </div>
                <div class="weekly-caption">
                  <h4><a href="#">Cérémonie de remise des diplomes 2019</a></h4>
                </div>
              </div>
              <div class="weekly-single">
                <div class="weekly-img">
                  <img src="assets/img/news/1.jpg" alt="" />
                </div>
                <div class="weekly-caption">
                  <h4>
                    <a href="#"
                      >L'institution universitaire la plus ancinenne</a
                    >
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Weekly-News -->

  <!-- End pagination  -->
</main>
<section class="contact-section">
  <div class="container">
    <div class="d-none d-sm-block mb-5 pb-4"></div>

    <div class="row">
      <div class="col-12">
        <h2 class="contact-title">CONTACT FORM</h2>
      </div>
      <div class="col-lg-8">
        <form
          class="form-contact contact_form"
          action="contact_process.php"
          method="post"
          id="contactForm"
          novalidate="novalidate"
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <textarea
                  class="form-control w-100"
                  name="message"
                  id="message"
                  cols="30"
                  rows="9"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Enter Message'"
                  placeholder=" Enter Message"
                ></textarea>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  class="form-control valid"
                  name="name"
                  id="name"
                  type="text"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Enter your name'"
                  placeholder="Enter your name"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  class="form-control valid"
                  name="email"
                  id="email"
                  type="email"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Enter email address'"
                  placeholder="Email"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  name="subject"
                  id="subject"
                  type="text"
                  onfocus="this.placeholder = ''"
                  onblur="this.placeholder = 'Enter Subject'"
                  placeholder="Enter Subject"
                />
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <button type="submit" class="button button-contactForm boxed-btn">
              Send
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-3 offset-lg-1">
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-home"></i></span>
          <div class="media-body">
            <h3>8, Place aux chevaux 1089</h3>
            <p>EL Gorjani Tunis</p>
          </div>
        </div>
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-tablet"></i></span>
          <div class="media-body">
            <h3>71 561 362 / 71 563 237/ 71 260 374</h3>
            <p></p>
          </div>
        </div>
        <div class="media contact-info">
          <span class="contact-info__icon"><i class="ti-email"></i></span>
          <div class="media-body">
            <h3>ens@ens.rnu.tn</h3>
            <p>Send us your query anytime!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<footer>
  <!-- Footer Start-->
  <div class="footer-area footer-padding fix">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-12">
          <div class="single-footer-caption">
            <div class="single-footer-caption">
              <!-- logo -->
              <div class="footer-logo">
                <a href="index.html"
                  ><img src="assets/img/logo/logo-enstfr.png" alt=""
                /></a>
              </div>
              <div class="footer-tittle">
                <div class="footer-pera">
                  <p>
                    L’Ecole normale supérieure est l’institution universitaire
                    la plus ancienne, et le premier noyau d’enseignement
                    supérieur moderne de la Tunisie indépendante. En effet,
                    l’E.N.S a ouvert ses portes dès octobre 1956. Le texte
                    officiel de sa création, daté du 13 septembre 1958, lui
                    donnait pour mission d’assurer la formation d’enseignants,
                    de cadres et de chercheurs de haut niveau dont le pays avait
                    un si grand besoin.
                  </p>
                </div>
              </div>
              <!-- social -->
              <div class="footer-social">
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-instagram"></i></a>
                <a href="#"><i class="fab fa-pinterest-p"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
          <div class="single-footer-caption mt-60"></div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-6"></div>
      </div>
    </div>
  </div>
  <!-- footer-bottom aera -->
  <div class="footer-bottom-area">
    <div class="container">
      <div class="footer-border">
        <div class="row d-flex align-items-center justify-content-between">
          <div class="col-lg-6">
            <div class="footer-copy-right">
              <p>
                Copyright &copy; Tous droits réservés 2021 Ecole Normale
                Superieure de Tunis .
              </p>
            </div>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End-->
</footer>
