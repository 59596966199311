import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadre-juridique',
  templateUrl: './cadre-juridique.component.html',
  styleUrls: ['./cadre-juridique.component.css']
})
export class CadreJuridiqueComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
