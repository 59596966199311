<header>
  <!-- Header Start -->
  <div class="header-area">
    <div class="main-header">

      <div class="header-mid d-none d-md-block">
        <div class="container">
          <div class="row d-flex align-items-center">
            <!-- Logo -->
            <div class="col-xl-3 col-lg-3 col-md-3">
              <div class="logo">
                <a href="index.html"
                  ><img src="assets/img/logo/logo-enstfr.png" alt=""
                /></a>
              </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9">
              <div class="header-banner f-right">
                <img src="assets/img/logo/logo2.PNG" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bottom header-sticky">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-10 col-lg-10 col-md-12 header-flex">
              <!-- sticky -->

              <!-- Main-menu -->
              <div class="main-menu d-none d-md-block">
                <nav>
                  <ul id="navigation">
                    <li><a href="index.html">Accueil</a></li>

                    <li>
                      <a href="about.html">Gouvernance</a>
                      <ul class="submenu">
                        <li><a [routerLink]="['/cadrejuridique']">Cadre juridque</a></li>
                        <li>
                          <a [routerLink]="['/administration-de-le-n-s']">Administration l'ENS</a>
                          <ul class="submenu">

                            <li>
                              <a href="elements.html">Directions de l'ENS</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="latest_news.html">Formation</a>
                      <ul class="submenu">
                        <li>
                          <a href="blog.html">Inscription et scolarité</a>
                        </li>
                        <li>
                          <a href="single-blog.html">Programmes annuels</a>
                        </li>
                        <li>
                          <a href="details.html">Descriptifs des cours</a>
                        </li>
                        <li>
                          <a href="single-blog.html">Formation initiale</a>
                        </li>
                        <li>
                          <a href="details.html">Formation complémentaire</a>
                        </li>
                        <li><a href="details.html">Stage pédagogique</a></li>
                      </ul>
                    </li>

                    <li>
                      <a href="contact.html">Départment et recherche</a>
                      <ul class="submenu">
                        <li>
                          <a href="elements.html">Départment</a>
                          <ul class="submenu">
                            <li>
                              <a href="elements.html">Départment des langues</a>
                            </li>
                            <li>
                              <a href="blog.html">Départment des Sc.humaines</a>
                            </li>
                            <li>
                              <a href="single-blog.html"
                                >Départment des Sc.fondamentales</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="blog.html">Recherche</a>
                          <ul class="submenu">
                            <li>
                              <a href="elements.html"
                                >Structures de recherches</a
                              >
                            </li>
                            <li>
                              <a href="blog.html">Activités Scientifiques</a>
                            </li>
                            <li>
                              <a href="single-blog.html"
                                >Publications de l'E.N.S</a
                              >
                            </li>
                            <li><a href="blog.html">Soutenances</a></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="contact.html">Bibliothéque</a>
                      <ul class="submenu">
                        <li><a href="elements.html">Présentation</a></li>
                        <li><a href="blog.html">Cataloge Universitaire</a></li>
                        <li>
                          <a href="single-blog.html"
                            >Cataloge de Bibliothéque</a
                          >
                        </li>
                        <li>
                          <a href="details.html">Don pour la Bibliothéque</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Espace Enseignants</a>
                      <ul class="submenu">
                        <li>
                          <a href="elements.html">Cadre enseignants de l'ENS</a>
                          <ul class="submenu">
                            <li>
                              <a href="elements.html"
                                >Professeurs de l'enseignement supérieur</a
                              >
                            </li>
                            <li>
                              <a href="blog.html">Maitres de conférences</a>
                            </li>
                            <li>
                              <a href="single-blog.html">Maitres-assitants</a>
                            </li>
                            <li><a href="details.html">Assistant</a></li>
                            <li><a href="single-blog.html">Agrégés</a></li>
                            <li>
                              <a href="details.html"
                                >Professeurs tronc commun</a
                              >
                            </li>
                          </ul>
                        </li>
                        <li><a href="blog.html">Professeur Visiteurs</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-4">
              <div class="header-right-btn f-right d-none d-lg-block">
                <i class="fas fa-search special-tag"></i>
                <div class="search-box">
                  <form action="#">
                    <input type="text" placeholder="Search" />
                  </form>
                </div>
              </div>
            </div>
            <!-- Mobile Menu -->
            <div class="col-12">
              <div class="mobile_menu d-block d-md-none"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End -->
</header>
