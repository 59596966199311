<footer>
  <!-- Footer Start-->
  <div class="footer-area footer-padding fix">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-12">
          <div class="single-footer-caption">
            <div class="single-footer-caption">
              <!-- logo -->
              <div class="footer-logo">
                <a href="index.html"
                  ><img src="assets/img/logo/logo-enstfr.png" alt=""
                /></a>
              </div>
              <div class="footer-tittle">
                <div class="footer-pera">
                  <p>
                    L’Ecole normale supérieure est l’institution universitaire
                    la plus ancienne, et le premier noyau d’enseignement
                    supérieur moderne de la Tunisie indépendante. En effet,
                    l’E.N.S a ouvert ses portes dès octobre 1956. Le texte
                    officiel de sa création, daté du 13 septembre 1958, lui
                    donnait pour mission d’assurer la formation d’enseignants,
                    de cadres et de chercheurs de haut niveau dont le pays avait
                    un si grand besoin.
                  </p>
                </div>
              </div>
              <!-- social -->
              <div class="footer-social">
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-instagram"></i></a>
                <a href="#"><i class="fab fa-pinterest-p"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6">
          <div class="single-footer-caption mt-60"></div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-6"></div>
      </div>
    </div>
  </div>
  <!-- footer-bottom aera -->
  <div class="footer-bottom-area">
    <div class="container">
      <div class="footer-border">
        <div class="row d-flex align-items-center justify-content-between">
          <div class="col-lg-6">
            <div class="footer-copy-right">
              <p>
                Copyright &copy; Tous droits réservés 2021 Ecole Normale
                Superieure de Tunis .
              </p>
            </div>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End-->
</footer>
